<template>
  <div class="wrap__contents countries-inner">
    <div class="toolbar">
      <search
        :translations="translations"
        :filter-by="filterBy"
        :date-by="dateBy"
        @refresh="search"
        @trigger="trigger"
        @clear="search"
      />
    </div>

    <div
      class="wrap__inner"
    >
      <tbl
        ref="tbl"
      >
        <template slot="head">
          <tbl-td name="checkbox">
            <checkbox>
              <input
                type="checkbox"
              >
            </checkbox>
          </tbl-td>

          <tbl-td name="date">
            {{ translate(translations, 'date') }}
          </tbl-td>

          <tbl-td name="cash-in">
            {{ translate(translations, 'cash in') }}
          </tbl-td>

          <tbl-td name="cash-out">
            {{ translate(translations, 'cash out') }}
          </tbl-td>

          <tbl-td name="cash-net">
            {{ translate(translations, 'cash net') }}
          </tbl-td>

          <tbl-td name="manual-in">
            {{ translate(translations, 'manual in') }}
          </tbl-td>

          <tbl-td name="manual-out">
            {{ translate(translations, 'manual out') }}
          </tbl-td>

          <tbl-td name="bet">
            {{ translate(translations, 'bet') }}
          </tbl-td>

          <tbl-td name="win">
            {{ translate(translations, 'win') }}
          </tbl-td>

          <tbl-td name="bet-net">
            {{ translate(translations, 'bet net') }}
          </tbl-td>

          <tbl-td name="point-in">
            {{ translate(translations, 'point in') }}
          </tbl-td>

          <tbl-td name="point-out">
            {{ translate(translations, 'point out') }}
          </tbl-td>

          <tbl-td name="bet-users">
            {{ translate(translations, 'bet users') }}
          </tbl-td>

          <tbl-td name="new-users">
            {{ translate(translations, 'new users') }}
          </tbl-td>
        </template>

        <template slot="body">
          <tbl-row>
            <tbl-td name="checkbox">
              <checkbox
                @click.native.stop
              >
                <input
                  type="checkbox"
                >
              </checkbox>
            </tbl-td>

            <tbl-td name="date" />

            <tbl-td name="cash-in" />

            <tbl-td name="cash-out" />

            <tbl-td name="cash-net" />

            <tbl-td name="manual-in" />

            <tbl-td name="manual-out" />

            <tbl-td name="bet" />

            <tbl-td name="win" />

            <tbl-td name="bet-net" />

            <tbl-td name="point-in" />

            <tbl-td name="point-out" />

            <tbl-td name="bet-users" />

            <tbl-td name="new-users" />
          </tbl-row>

          <!--
            ***
              TOTAL
            ***
          -->
          <tbl-row>
            <tbl-td name="checkbox" />

            <tbl-td name="date">
              {{ translate(translations, 'sum') }}
            </tbl-td>

            <tbl-td name="cash-in" />

            <tbl-td name="cash-out" />

            <tbl-td name="cash-net" />

            <tbl-td name="manual-in" />

            <tbl-td name="manual-out" />

            <tbl-td name="bet" />

            <tbl-td name="win" />

            <tbl-td name="bet-net" />

            <tbl-td name="point-in" />

            <tbl-td name="point-out" />

            <tbl-td name="bet-users" />

            <tbl-td name="new-users" />
          </tbl-row>
        </template>
      </tbl>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

//= components
import Pagination from '@/components/base/Pagination'
import Search from '@/components/base/search/Search'
import Modal from '@/components/base/Modal'
import Checkbox from '@/components/base/Checkbox'
import CheckDataList from '@/components/base/CheckDataList'
import AttachImage from '@/components/base/AttachImage'

//= mixin
import { checkAll } from '@/assets/js/mixins/base/CheckAll'
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'

//= translation
import { translations } from '@/assets/js/translations/Account'

export default {
  name: 'Accounting',

  components: {
    Pagination,
    Search,
    Modal,
    Checkbox,
    CheckDataList,
    AttachImage
  },

  mixins: [
    checkAll,
    getListStatus
  ],

  data () {
    return {
      translations,
      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false,
      showTblSettings: false,
      selected: [],
      selectAll: false,

      dateBy: [
        {
          value: 'created_at',
          display: 'created date'
        },

        {
          value: 'updated_at',
          display: 'updated date'
        },

        {
          value: 'deleted_at',
          display: 'deleted date'
        }
      ],
      filterBy: []
    }
  },

  methods: {
    search () {
      //
    },

    trigger () {
      //
    }
  }
}
</script>
